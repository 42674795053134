:root {
  /* colors */
  --dark: hsl(240, 10%, 21%);
  --dark-solid: #080707;
  --light: #a8b6c6;
  --lighter: #f4f7fc;
  --shadow: #dcdfe6;
  --success: #2ddaba;
  --success-light: #dcfff8;
  --danger: #ea4955;
  --danger-light: #ffe9f6;
  --warning: #ffbc02;
  --warning-light: #fff0e1;
  --primary: #1a92ff;
  --primary-light: #d9edff;
  --purple: #5849b5;
  --purple-light: #dfdaff;
  --dark-pink: #ea4c89;
  --dark-pink-light: #ff70a7;
  --pink-light: #ffe6ef;

  --primary: #2f65f0;

  /* nav */
  --nav-bg: #dcdfe6;
}


html, body{
  font-family: 'Inter';
}

/* Focusing the button with a keyboard will show a dashed black line. */
button:focus {
  outline: none;
}

input, optgroup, select, textarea:focus{
  outline: none;
}

.nav-main{
  position: fixed;
  row-gap: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  padding: 20px;
}


.nav-main button{
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
  color: white;
  transition: 0.3s;
  font-size: 20px;
}

.nav-main button.active{
  background-color: white;
  color: var(--primary);
}

.nav-main button:hover{
  background-color: white;
  color: var(--primary);
}

.nav-main button font{
  display: none;
}

.nav-main button:hover > .font{
  display: block;
}

.main{
  margin-left: 80px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
}

.main .title{
  font-size: 35px;
  font-weight: 700;
  font-family: 'Heebo';
}

/* items */
.items .item{
  display: flex;
  align-items: center;
  min-width: 30%;
  padding: 20px;
  background: var(--primary);
  border: solid 1.5px var(--primary-light);
  color: white;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.badger{
  border: none;
  border-radius: 10px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  color: var(--primary);
  font-weight: 700;
  margin-left: 10px;
}

.items .item:hover{
  background: var(--primary-light);
  color: var(--primary);
}

.items .item:hover > button{
  background: white;
  color: var(--primary);
}

.items .item .name{
  font-weight: 700;
  font-size: 20px;
}



.btn-init[disabled]{
  color: var(--white);
  background: var(--light);
  cursor: not-allowed;
}

.btn-init[disabled]:hover{
  color: var(--white);
  background: var(--light);
}

.btn-init{
  border: none;
  border-radius: 10px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--primary);
  color: white;
  font-weight: 700;
  transition: 0.3s;
}

.btn-init:hover{
  color: var(--primary);
  background: var(--primary-light);
}

.btn-del{
  border: none;
  border-radius: 10px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--pink);
  color: white;
  font-weight: 700;
}

.btn-del:hover{
  color: var(--pink);
  background: var(--pink-light);
}

.small-title{
  color: #181529;
  font-size: 15px;
  font-weight: 600;
  font-family: "Inter";
}

/* compose */
.editable-input{
  border: 1px solid var(--light);
  background: var(--lighter);
  padding: 10px;
  padding-left: 20px;
  border-radius: 15px;
}

.editable-input:focus{
  border: 1px solid var(--primary);
  background: white;
}

.selectable{
  height: 45px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: var(--primary);
  color: white;
  margin-right: 20px;
  margin-bottom: 15px;
}

.selectable.selected{
  background-color: var(--primary-light);
  color: var(--primary);
}

.selectable:hover{
  background-color: var(--primary-light);
  color: var(--primary);
}

.ver{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.demo-email{
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-email input{
  width: calc(100% - 10px);
  margin-top: 15px;
  margin-right: 10px;
}

.demo-email button{
  width: 200px;
}




.preview-final{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 650px;
  background: white;
  overflow-y: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.185);
  padding: 20px;
}

.pad-r-temp{
  margin-right: 40vw;
}

.preview-small{
  width: 39.5vw;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  overflow-y: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.185);
  padding: 20px;
}


/* my-modal */
.my-modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.my-modal .background {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.26);
  backdrop-filter: blur(12px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.my-modal .inner{
  position: fixed;
  top: 20vh;
  bottom: 20vh;
  left: 25vw;
  right: 25vw;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.my-modal .inner .sub-inner{
   background-color: white;
   padding: 30px;
   border-radius: 10px;
   min-width: 750px;
   max-width: 750px;
}


.close-btn{
  float: right;
  margin-bottom: 10px;
  border-radius: 50px;
  border: none;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--dark);
}

.close-btn:hover{
  background-color: var(--primary);
  color: white;
}


.my-modal .inner input{
  width: 100%;
  font-weight: 700;
  font-family: "Inter";
}

.my-modal .inner .title{
  font-weight: 600;
  font-family: "Inter";
  font-size: 15px;
}



.emails{
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.emails .email {
  border: none;
  border-radius: 10px;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: light;
  color: var(--dark);
  margin-bottom: 5px;
  margin-right: 10px;
  font-size: 15px;
}

.emails .email:hover{
  background-color: var(--pink);
  color: white;
}

.emails .email i{
  float: right;
  padding-left: 10px;
  margin-top: 5px;
}

.emails .email:hover > i{
  visibility: visible;
}


.login{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}


.login .inner{
  max-width: 400px;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.login .title-min{
  font-size: 35px;
  font-weight: 800;
  font-family: "Poppins";
  transform: skewX(-20deg);
  color: var(--primary);
}

.login .inner input:focus{
  border: solid 1.5px var(--primary);
}
.login .inner input{
  width: 100%;
  border: solid 1.5px var(--primary);
  border-radius: 10px;
  height: 50px;
  margin-bottom: 15px;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.034);
}

.small{
  color: var(--dark);
  font-size: 13px;
}